import moment from 'moment';
import { constants } from '../../../constants';

interface Props {
    publishingDate: Date;
}

const renderTimeLabel = (publishingDate: Date) => {
    const now = moment();
    const newsDate = moment(publishingDate);

    const hoursPassed = Math.floor(moment.duration(now.diff(newsDate)).asHours());

    if (hoursPassed >= 24 || hoursPassed < 0) {
        return newsDate.utc().format(constants.dateFormatDoubleDay);
    }

    // ¯\_(ツ)_/¯
    if (hoursPassed <= 1) {
        return `1 hour ago`;
    }

    return `${hoursPassed} hours ago`;
};

export const TimeLabel = ({ publishingDate }: Props) => (
    <time className="news-item-time">{renderTimeLabel(publishingDate)}</time>
);
