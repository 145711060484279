import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { WizardHeader } from '../';
import { TemplateList, TemplateDetails } from '../../process';
import { processActions as actions } from '../../../actions';
import { Preloader } from '../../common';
import { newBwicDealersActions } from '../../../actions/new-bwic-dealers.actions';

export function Process() {
    const dispatch = useDispatch();

    const templates = useSelector(state => state.process.templates.all);
    const isTemplateProcessing = useSelector(state => state.process.isTemplateProcessing);
    const isValid = useSelector(state => state.process.isValid);

    useEffect(() => {
        dispatch(actions.init());
        dispatch(newBwicDealersActions.resetSelectedByDefaultFlag());
    }, [dispatch]);

    const nextButtonEnabled = !isTemplateProcessing && isValid;

    return (
        <Preloader inProgress={!templates}>
            {
                !!templates &&
                <>
                    <div className="sub-header">
                        <WizardHeader hasError={!isValid} nextButtonEnabled={nextButtonEnabled} />
                    </div>
                    <div className="container-step-process container-flex container-sidebar">
                        <TemplateList />
                        <TemplateDetails />
                    </div>
                </>
            }
        </Preloader>
    );
}
