import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Securities, Documents, Process } from './steps';
import { Submit } from './steps/Submit';
import { NewBwicDealers } from './steps/NewBwicDealers';
import { newBWICSteps as steps } from '../../constants';
import { newBWICActions as actions } from '../../actions';
import { RouteLeavingGuard } from '../routing';
import { DirectBiddingDisclaimerContentBlocker } from '../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerContentBlocker';
import { bwicChangedFlagSelector } from '../../selectors/bwic-changed-flag.selector';
import { isRequesting, isRequestSuccess } from '../../utils';

class Wizard extends Component {
    componentDidMount = () => this.props.dispatch(actions.init(this.props.match.params.referenceName));

    componentWillUnmount = () => this.props.dispatch(actions.reset());

    render = () => {
        const { activeStep, history, isBwicChanged } = this.props;
        const step = this.getActiveStep(activeStep);

        return (
            <DirectBiddingDisclaimerContentBlocker>
                <div className="container container-new-bwic">
                    {step}
                </div>
                <RouteLeavingGuard
                    navigate={pathname => history.push(pathname)}
                    shouldBlockNavigation={() => isBwicChanged}
                />
            </DirectBiddingDisclaimerContentBlocker>
        );
    }

    getActiveStep = (activeStep) => {
        switch (activeStep) {
            case steps.securities: return <Securities />;
            case steps.process: return <Process />;
            case steps.participants: return <NewBwicDealers />;
            case steps.documents: return <Documents />;
            case steps.submit: return <Submit />;
            default: return null;
        };
    }
}

const mapStateToProps = ({ newBWIC, submit, grid, process, securities }) => ({
    activeStep: newBWIC.activeStep,
    isBwicChanged: bwicChangedFlagSelector({
        isInitializing: securities.isInitializing,
        activeStep: newBWIC.activeStep,
        submitted: isRequestSuccess(submit.submitState) || isRequesting(submit.submitState),
        hasBwicPositions: grid.dataItems.filter(i => !i.draft).length > 0,
        process
    })
});

const connectedWizard = connect(mapStateToProps)(Wizard);
export { connectedWizard as Wizard };
