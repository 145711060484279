import React from "react";
import { FlexRow } from "../common";
import { newBWICActions as actions } from '../../actions';
import { Checkbox, Popup, PopupBody, PopupFooter } from "../controls";
import { useSelector } from "react-redux";
import { newBwicDealersActions } from "../../actions/new-bwic-dealers.actions";
import { apiUtils } from "../../utils";
import { AppState } from "../../types/state/AppState";
import { useState } from "react";
import { BrokerDealerCompanySlim } from "../../types/company/BrokerDealerCompanySlim";
import { StatusMessageSectionType } from "../../types/state/NotificationState";
import { StatusMessageSection } from "../status-message/StatusMessageSection";
import { CompanyStatus } from "../../types/company/CompanyStatus";
import { newBwicDealersSelector } from "../../selectors/new-bwic-dealers.selector";
import { useAppDispatch } from "../../effects/useAppDispatch";


export default function SelectPlatformDealersPopup() {
    const dispatch = useAppDispatch()
    const newBwicDealerList = useSelector(newBwicDealersSelector);
    const selectedDealers = useSelector((state: AppState) => state.newBwicDealers.selectedDealers);
    const notSelectedPlatformDealers = newBwicDealerList.filter(c => !selectedDealers[c.id] && c.status === CompanyStatus.active);

    const [dealersList] = useState(notSelectedPlatformDealers)

    const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedSelectedDealers = {
            ...selectedDealers,
            ...apiUtils.normalize(dealersList, d => d.id, () => e.target.checked),
        };
        dispatch(newBwicDealersActions.selectDealers(updatedSelectedDealers));
    };

    const handleClosePopup = () => dispatch(newBwicDealersActions.setSelectPlatformDealersPopupVisible(false));

    return (
        <Popup
            modalClass="add-dealers"
            title="Add Platform Broker-Dealers"
            onClose={handleClosePopup}
            renderInBody
        >
            <PopupBody>
                <StatusMessageSection type={StatusMessageSectionType.Alert}>
                    We recommend adding the following banks to your BWIC:&nbsp;
                    <span className="dealers">
                        {dealersList.map((d: BrokerDealerCompanySlim) => <strong className='text-medium' key={d.id}>{d.name}</strong>)}
                    </span>
                    <span className="display-inline note">
                        They are registered platform members, able to stream prices electronically online. Adding platform banks might improve your BWIC experience.
                    </span>
                </StatusMessageSection>
                <Checkbox
                    label="Add Recommended Broker-Dealers"
                    onChange={handleSelect}
                    checked={notSelectedPlatformDealers.length === 0}
                />
            </PopupBody>
            <PopupFooter>
                <FlexRow>
                    <button
                        type="button"
                        className="btn btn-ghost"
                        onClick={handleClosePopup}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-main"
                        onClick={() => {
                            dispatch(actions.nextStep())
                            handleClosePopup();
                        }}
                    >
                        Next step
                    </button>
                </FlexRow>
            </PopupFooter>
        </Popup>
    )

};
