import React from 'react';

interface NewsListContextProps {
    canEdit: boolean;
}

const NewsListContext = React.createContext<NewsListContextProps>({
    canEdit: false,
});

export default NewsListContext;

