import qs from 'query-string';
import { News } from '../../../types/news/News';
import { TruncateHtml } from './TruncateHtml';
import { routes } from '../../../constants';
import { TimeLabel } from './TimeLabel';
import { EditNewsTabType } from '../../../types/news/enums/EditNewsTabType';
import IconSVG from '../../../styles/svg-icons';
import { NewsPageRouteQuery } from '../news-page/NewsPage';
import { NewsCategory, newsCategoryTitles } from '../../../types/news/enums/NewsCategory';
import { NewsLabel, newsLabelTitles } from '../../../types/news/enums/NewsLabel';
import { NewsProvider, newsProviderLogos, newsProviderTitles } from '../../../types/news/enums/NewsProvider';
import { forwardRef, useContext } from 'react';
import NewsListContext from './NewsListContext';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { newsActions } from '../../../actions/news.actions';
import { NewsAccessType } from '../../../types/amr-pipeline/enums/NewsAccessType';
import { useHistory } from 'react-router';
import { RequiredFeature } from '../../access/RequiredFeature';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../../access/ActionBlocker';
import { FeatureButton } from '../../access/FeatureButton';
import { BlockedFeatureContent, SubscribeLink } from '../../../components/access/BlockedFeatureText';
import { useDownloadDocument } from '../../../effects/useDownloadDocument';
import { newsService } from '../../../services';
import { Preloader } from '../../common';

interface NewsProps {
    newsItem: News;
}

interface NewsTopSectionProps extends NewsProps {
    onDeleting?: (newsItem: News) => void;
}

interface NewsItemProps extends NewsTopSectionProps {
    currentCategory?: NewsCategory;
    currentPage: number;
}

const NewsTopSection = ({ newsItem, onDeleting }: NewsTopSectionProps) => {
    const { canEdit } = useContext(NewsListContext);
    const isDraft = canEdit && !newsItem.published;

    return (
        <div className="flex-row news-controls">
            {isDraft && (
                <div className="news-controls-item">
                    <div className="label-status label-status-draft">draft</div>
                </div>
            )}
            {newsItem.category !== NewsCategory.None && (
                <div className="news-controls-item">
                    <div
                        className={classNames(
                            'label-status label-status-news-category',
                            `label-status-${newsCategoryTitles[newsItem.category].toLowerCase().replace(' ', '-')}`,
                        )}
                    >
                        {newsCategoryTitles[newsItem.category]}
                    </div>
                </div>
            )}

            {newsItem.provider && newsItem.provider !== NewsProvider.None && (
                <div className="news-controls-item">
                    <div className="news-provider-clo">
                        <div
                            className={classNames({
                                'news-provider-london-stock-exchange-group':
                                    NewsProvider.LondonStockExchangeGroup === newsItem.provider,
                                'news-provider-clo-img': NewsProvider.CLOResearchGroup === newsItem.provider,
                            })}
                        >
                            <img
                                src={newsProviderLogos[newsItem.provider]}
                                alt={newsProviderTitles[newsItem.provider]}
                            />
                        </div>
                        <span className="news-provider-clo-text">
                            Provided by {newsProviderTitles[newsItem.provider]}
                        </span>
                    </div>
                </div>
            )}

            {newsItem.label !== NewsLabel.None && (
                <div className="news-controls-item">
                    <div className="news-label text-sm">{newsLabelTitles[newsItem.label]}</div>
                </div>
            )}

            {canEdit && (
                <div className="flex-row news-controls-edit">
                    <div className="news-controls-item news-controls-item-edit">
                        <a
                            className="btn-link"
                            href={routes.manageNewsUrl(newsItem.referenceName, EditNewsTabType.edit)}
                        >
                            <IconSVG name="edit-pencil" width={16} height={16} />
                        </a>
                    </div>
                    <div className="news-controls-item news-controls-item-edit">
                        <button className="btn-link btn-danger" onClick={() => onDeleting && onDeleting(newsItem)}>
                            <IconSVG name="basket" width={16} height={16} />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export const NewsAdditionalSection = ({ newsItem }: NewsProps) => {
    const { externalSourceLink, transactionLink, cloManagerReferenceName, arrangerReferenceName, newsDocuments } =
        newsItem;
    const dispatch = useDispatch();

    const { handleDownload, isDownloading } = useDownloadDocument({
        downloadRequest:
            newsItem.newsDocuments?.length === 1 ? newsService.downloadDocument : newsService.downloadDocuments,
        requestArgs:
            newsItem.newsDocuments?.length === 1
                ? [newsItem.referenceName, newsItem.newsDocuments[0].referenceName]
                : [newsItem.referenceName, ''],
        fileNameForSave: newsItem.newsDocuments?.length === 1 ? newsItem.newsDocuments[0].name || null : null,
    });

    const handleDownloadFilesClick = () => {
        if (!newsItem.newsDocuments?.length) {
            return;
        }

        handleDownload();

        dispatch(newsActions.logUserActivity(NewsAccessType.newsAllDocuments));
    };

    return (
        <div className="flex-row news-item-additional">
            <div className="news-item-additional-item">
                <TimeLabel publishingDate={newsItem.newsDateTime} />
            </div>
            {!!externalSourceLink && (
                <div className="news-item-additional-item">
                    <a
                        onClick={() => dispatch(newsActions.logUserActivity(NewsAccessType.externalSource))}
                        href={externalSourceLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        External Source
                    </a>
                </div>
            )}
            {!!transactionLink && (
                <div className="news-item-additional-item">
                    <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                        {blocked => (
                            <a
                                onClick={() => dispatch(newsActions.logUserActivity(NewsAccessType.dealDetails))}
                                target="_blank"
                                href={transactionLink}
                                rel="noreferrer"
                                className={classNames({ disabled: blocked })}
                            >
                                Deal Details
                            </a>
                        )}
                    </ActionBlocker>
                </div>
            )}
            {!!cloManagerReferenceName && (
                <div className="news-item-additional-item">
                    <a
                        onClick={() => dispatch(newsActions.logUserActivity(NewsAccessType.managerProfile))}
                        href={routes.manageCloManagerUrl(cloManagerReferenceName)}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Manager Profile
                    </a>
                </div>
            )}
            {!!arrangerReferenceName && (
                <div className="news-item-additional-item">
                    <a
                        onClick={() => dispatch(newsActions.logUserActivity(NewsAccessType.arranger))}
                        href={routes.manageBanksUrl(arrangerReferenceName)}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Arranger
                    </a>
                </div>
            )}
            {!!newsDocuments?.length && (
                <ActionBlocker feature={SubscriptionFeature.KWatchNews}>
                    <div className="news-item-additional-item">
                        {isDownloading ? (
                            <Preloader inProgress text="Downloading..." small fullScreen={false} />
                        ) : (
                            <FeatureButton
                                className="btn btn-ghost btn-sm btn-no-uppercase btn-download-attachment"
                                text="Download attachment"
                                onClick={handleDownloadFilesClick}
                            >
                                <IconSVG name="paperclip" width={16} height={16} />
                            </FeatureButton>
                        )}
                    </div>
                </ActionBlocker>
            )}
        </div>
    );
};

export const NewsItem = forwardRef(
    (
        { newsItem, currentCategory, currentPage, onDeleting }: NewsItemProps,
        ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
        const dispatch = useDispatch();
        const history = useHistory();

        const getNewsPageUrl = (referenceName: string) => {
            const query: NewsPageRouteQuery = {
                category: currentCategory,
            };

            const queryString = qs.stringify(query);

            return `${routes.newsUrl(referenceName)}?${queryString}`;
        };

        const handleDetailsClick = () => {
            dispatch(newsActions.logUserActivity(NewsAccessType.readMore));

            const queryString = qs.stringify({
                search: newsItem.referenceName,
                category: currentCategory,
                page: currentPage,
            });

            history.replace(`${routes.newsUrl()}?${queryString}`); // saving previous page query for scroll after click on browser back button
            history.push(getNewsPageUrl(newsItem.referenceName));
        };

        const readMoreButton = () => (
            <RequiredFeature
                feature={SubscriptionFeature.KWatchNews}
                renderBlockedContent={() => (
                    <BlockedFeatureContent
                        inline
                        className="placeholder-read-more"
                        text={
                            <>
                                <SubscribeLink /> to read more
                            </>
                        }
                    />
                )}
            >
                <span className="news-item-read-more btn-link secondary regular" onClick={handleDetailsClick}>
                    Read more
                </span>
            </RequiredFeature>
        );

        return (
            <div className="news-item" ref={ref}>
                <NewsTopSection newsItem={newsItem} onDeleting={onDeleting} />
                <h2 className="news-item-title">
                    <ActionBlocker feature={SubscriptionFeature.KWatchNews} noTooltip>
                        {blocked => (
                            <span
                                onClick={handleDetailsClick}
                                className={classNames('btn-link', { disabled: blocked })}
                            >
                                {newsItem.title}
                            </span>
                        )}
                    </ActionBlocker>
                </h2>
                <p className="news-item-description">
                    <TruncateHtml
                        html={newsItem.description}
                        maxLength={129}
                        initiallyTruncated={newsItem.isTruncated}
                        readMoreLink={readMoreButton()}
                    />
                </p>
                <NewsAdditionalSection newsItem={newsItem} />
            </div>
        );
    },
);
