import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressBar } from './';
import { newBWICSteps as steps } from '../../constants';
import { newBWICActions as actions } from '../../actions';
import IconSVG from '../../styles/svg-icons';
import { StatusMessageSection } from "../status-message/StatusMessageSection";
import { StatusMessageSectionType } from "../../types/state/NotificationState";
import { BwicProcessType } from '../../types/models/Process';
import { isRequesting, isRequestSuccess } from '../../utils';

class WizardHeader extends Component {
    renderSendButton = () => (
        <button className="btn btn-main" onClick={this.handleSubmit} disabled={this.props.isStepNavigationDisabled}>
            <i className="icon icon-submit" />
            Send
        </button>
    );

    renderPreviousButton = () => {
        if (
            this.props.isCompleted || this.props.activeStep === steps.securities || (
                this.props.activeStep === steps.submit && this.props.isStepNavigationDisabled)) {
            return null;
        } else {
            return (
                <button
                    className="btn btn-ghost btn-arrow btn-arrow-mirror"
                    onClick={this.handlePreviousClick}
                    disabled={
                        this.props.activeStep === steps.securities ||
                        this.props.isStepNavigationDisabled
                    }
                >
                    <IconSVG name="icon-expand" width={16} height={16} />
                </button>
            );
        }
    };

    renderNextButton = () => (
        <button
            className="btn btn-main btn-next"
            onClick={this.handleNextClick}
            disabled={
                this.props.isStepNavigationDisabled ||
                !this.props.nextButtonEnabled ||
                this.props.activeStep === steps.submit
            }
        >
            next <IconSVG name="icon-expand" width={16} height={16} />
        </button>
    );

    render = () => {
        const { activeStep, hasError, process, isSubmited, isSavingBWIC } = this.props;
        const isOptional = activeStep === steps.documents;
        const participateNotification =
            (activeStep === steps.process || activeStep === steps.submit) &&
            process?.type === BwicProcessType.Live &&
            !isSubmited

        return (
            <>
                <div className="sub-header-row type01 flex-row">
                    <ProgressBar isCompleted={this.props.isCompleted} stepHasError={hasError && activeStep} />
                    <div className="flex-item-right flex-row controls">
                        {isOptional &&
                            <StatusMessageSection type={StatusMessageSectionType.Info}>
                                This step is optional. You may skip it.
                            </StatusMessageSection>
                        }
                        {participateNotification && !isSavingBWIC &&
                            <StatusMessageSection type={StatusMessageSectionType.Info}>
                                Bidding is open for platform participants only
                            </StatusMessageSection>
                        }
                        {this.renderPreviousButton()}
                        {activeStep !== steps.submit && !this.props.isCompleted && this.renderNextButton()}
                        {activeStep === steps.submit && !this.props.isStepNavigationDisabled && this.renderSendButton()}
                    </div>
                </div>

                {this.props.children}
            </>
        );
    }

    handleNextClick = () => this.props.dispatch(actions.nextStep());
    handlePreviousClick = () => this.props.dispatch(actions.previousStep());
    handleSubmit = () => this.props.onSubmit && this.props.onSubmit();
}

const mapStateToProps = ({ newBWIC, process, submit }) => ({
    activeStep: newBWIC.activeStep,
    process: process.templates.current,
    isSubmited: isRequestSuccess(submit.submitState),
    isSavingBWIC: isRequesting(submit.submitState)
});

WizardHeader.defaultProps = {
    isStepNavigationDisabled: false,
    nextButtonEnabled: true,
};

const connectedWizardHeader = connect(mapStateToProps)(WizardHeader);
export { connectedWizardHeader as WizardHeader };

