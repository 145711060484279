import { useDispatch } from 'react-redux';
import { UsBslNewIssueDsWidget } from '../../../dashboard/usBslNewIssueSpreads/UsBslNewIssueDsWidget';
import { IssueTransactionVolumeWidget } from '../../../dashboard/issueTransactionVolume/IssueTransactionVolumeWidget';
import { ShortLongDatedDealsWidget } from '../../../dashboard/shortLongDatedDeals/ShortLongDatedDealsWidget';
import { pipelineRequestCriteria, routes } from '../../../../constants';
import { UsBslNewIssueWasWidget } from '../../../dashboard/usBslNewIssueSpreads/UsBslNewIssueWasWidget';
import { UsBslNewIssueWarfWidget } from '../../../dashboard/usBslNewIssueSpreads/UsBslNewIssueWarfWidget';
import { SectionContent } from '../../../amrPipeline/common/section/SectionContent';
import IconSVG from '../../../../styles/svg-icons';
import { ChartGrouping, ChartUnits } from '../../../../types/dashboard/AmrChart';
import { newsActions } from '../../../../actions/news.actions';
import { NewsAccessType } from '../../../../types/amr-pipeline/enums/NewsAccessType';
import { hasWidgetsAccess } from '../../../../utils/amr-pipeline.utils';
import { useEffect } from 'react';
import { dashboarNewIssuedWeeklySpreadsRequest } from '../../../../actions/dashboard.actions';
import { TransactionType } from '../../../../types/amr-pipeline/enums/TransactionType';
import { useHistory } from 'react-router';

export const PrimaryMarketHighlights = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const hasAccess = hasWidgetsAccess();

    useEffect(() => {
        hasAccess && dispatch(dashboarNewIssuedWeeklySpreadsRequest());
    }, [hasAccess, dispatch]);

    const handleGotoGraphs = () => {
        history.replace(`${routes.dashboard}#issuance-monitor`);
        dispatch(newsActions.logUserActivity(NewsAccessType.allGraphs));
    };

    return (
        <SectionContent
            title="Primary market highlights"
            actionBtn={
                <button className="btn-link flex-item-right" onClick={handleGotoGraphs}>
                    <IconSVG name="go-to" width={16} height={16} />
                    All graphs
                </button>
            }
        >
            <IssueTransactionVolumeWidget
                defaultFilter={{
                    transactionType: TransactionType.newIssue,
                    grouping: ChartGrouping.M,
                    units: ChartUnits.Count,
                }}
                withFilter={false}
            />
            <ShortLongDatedDealsWidget
                defaultFilter={{
                    units: ChartUnits.Count,
                }}
                periodsCount={6}
                withFilter={false}
                startDate={pipelineRequestCriteria.shortLongDatedDeals.startDateDashboard}
            />
            <UsBslNewIssueWasWidget />
            <UsBslNewIssueWarfWidget />
            <UsBslNewIssueDsWidget />
        </SectionContent>
    );
};
