import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Grid } from '../../grid';
import { WizardHeader } from '../';
import { securitiesActions, gridActions } from '../../../actions';
import { uploadStatus } from '../../../constants';
import { Preloader } from '../../common';
import { BwicDates } from '../../process/BwicDates';
import { isRequesting } from '../../../utils';
import { DownloadTemplateButton } from '../../common/download-template/DownloadTemplateButton';
import { DeleteRowsWithErrorButton } from '../../grid/DeleteRowsWithErrorButton';

class Securities extends Component {
    componentDidMount = () => {
        const { dispatch, bwic, location } = this.props;
        const { securities } = bwic || location.state || {};
        const queryParams = new URLSearchParams(this.props.location.search);
        dispatch(securitiesActions.init(securities, undefined, undefined, true, queryParams));
    };

    render = () => {
        const {
            hasSecuritiesErrors,
            hasNonDraftItems,
            securitiesTotalCount,
            isInitializing,
            isClipboardDataProcessing,
            isSecuritiesFileUploading,
            dispatch,
            isGridUploading,
            isDateValid,
            isValidatingTradeLimits
        } = this.props;

        if (isInitializing) {
            return <Preloader inProgress={true}/>;
        }

        if (securitiesTotalCount === 0) {
            return null;
        }

        const nextButtonEnabled = (
            hasNonDraftItems &&
            !isClipboardDataProcessing &&
            !isSecuritiesFileUploading &&
            !isGridUploading &&
            !hasSecuritiesErrors &&
            isDateValid &&
            !isValidatingTradeLimits);

        return (
            <>
                <div className="sub-header">
                    <WizardHeader hasError={hasSecuritiesErrors || !isDateValid} nextButtonEnabled={nextButtonEnabled} />
                    <div className="sub-header-row type02 flex-row align-items-flex-end">
                        <BwicDates />
                        <div className="flex-item-right controls">
                            <DeleteRowsWithErrorButton />
                            <DownloadTemplateButton templateType="SecuritiesXlsxTemplate" disabled={isGridUploading} />
                        </div>
                    </div>
                </div>

                <Grid
                    onDataItemsUpload={file => dispatch(securitiesActions.uploadSecurities(file))}
                    onDataItemsPaste={clipboardText => dispatch(securitiesActions.handleClipboardData(clipboardText))}
                    onFileSelected={file => dispatch(securitiesActions.uploadSecurities(file))}
                    helpPopoverTitle="You can add your securities in the following ways"
                />
               
            </>
        );
    };

    handleDeleteInvalidSecurities = () => this.props.dispatch(gridActions.deleteDataItemsWithErrors());
}

const mapStateToProps = ({ newBWIC, grid, securities, process }) => {
    const hasNonDraftItems = grid.dataItems.some(c => !c.draft)
    return {
        bwic: newBWIC.bwic,
        activeStep: newBWIC.activeStep,
        hasNonDraftItems,
        hasSecuritiesErrors: hasNonDraftItems && grid.dataItems.some(i => !i.draft && i.errors?.length),
        isClipboardDataProcessing: grid.isDataProcessing,
        isSecuritiesFileUploading: grid.upload.status === uploadStatus.uploading,
        securitiesTotalCount: grid.dataItems.length,
        isInitializing: securities.isInitializing,
        isGridUploading: grid.upload.status === uploadStatus.uploading,
        isDateValid: process.isDateValid,
        isValidatingTradeLimits: isRequesting(securities.validateTradeLimitsRequestState)
    }
};

const connectedSecurities = withRouter(connect(mapStateToProps)(Securities));
export { connectedSecurities as Securities };
