import React, { Component } from 'react';
import { connect } from 'react-redux';
import { newBWICSteps as steps } from '../../constants';
import { classnames } from '../../styles/classnames';

class ProgressBar extends Component {
    render = () => {
        const { activeStep = steps.securities, stepHasError, isCompleted } = this.props;
        let activeStepIndex = -1;

        const items = Object.keys(steps).map((key, index) => {
            const step = steps[key];
            const isActive = step === activeStep;

            if (isActive) {
                activeStepIndex = index;
            }

            const css = classnames({
                'is-active': isActive,
                'warning': steps[key] === stepHasError,
                passed: activeStepIndex < 0 || activeStepIndex > index || isCompleted,
            });

            return (
                <li key={index} className={css}>
                    {step}
                </li>);
        });

        return (
            <ul className="progress-steps">
                {items}
            </ul>
        )
    }
}

const mapStateToProps = ({ newBWIC }) => ({
    activeStep: newBWIC.activeStep
});

const connectedProgressBar = connect(mapStateToProps)(ProgressBar);
export { connectedProgressBar as ProgressBar };
