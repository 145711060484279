import React from 'react';
import { Pluralize } from '../../common';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { dealersSelector } from '../../../selectors/dealers.selector';
import { user } from '../../../user';
import { roles } from '../../../constants';

export const Summary = () => {
    const selectedDealers = useSelector((state: AppState) => state.newBwicDealers.selectedDealers);
    const brokerDealerContacts = useSelector((state: AppState) => state.entities.brokerDealerContactList.items);
    const brokerDealerUsers = useSelector((state: AppState) => state.entities.brokerDealerUserList.items);
    const isAllToAll = useSelector((state: AppState) => state.process.isAllToAll);
    const dealersList = useSelector(dealersSelector);
    const selectedCompanies = dealersList.filter(c => selectedDealers[c.id]);
    const selectedCompanyCount = selectedCompanies.length;
    const selectedParticipantCount = selectedCompanies
        .map(dealer => {
            const contacts = brokerDealerContacts.filter(c => c.companyId === dealer.id);
            const users = brokerDealerUsers.filter(u => u.companyId === dealer.id);
            const totalContacts = contacts.length + users.length;
            return dealer.headOfTrading == null ? totalContacts : totalContacts + 1
        })
        .reduce((a, b) => a + b, 0);
    const selectedDistributionListCount = selectedCompanies
        .map((c) => c.distributionList ? 1 : 0)
        .reduce<number>((a, b) => (a + b), 0);

    const distributionListText =
        <Pluralize
            count={selectedDistributionListCount}
            singular="distribution list"
            plural="distribution lists"
            template="{{text}}"
        />;
    const participantText = <Pluralize count={selectedParticipantCount} singular="participant" template="{{text}}" />;
    const companyText =
        <Pluralize
            count={selectedCompanyCount}
            singular="company"
            plural="companies"
            template="{{text}}"
        />;

    const renderLabel = () => {
        if (user.hasRoles(roles.BrokerDealerTrader) || isAllToAll) {
            return (
                <div className="selected-count">
                    BWIC will be sent anonymously to all platform members.
                </div>
            );
        }
        else if (!selectedParticipantCount) {
            return (
                <div className="selected-count">
                    BWIC will be sent to <span className="text-medium">{selectedDistributionListCount} {distributionListText}</span> from <span className="text-medium">{selectedCompanyCount} {companyText}</span>
                </div>
            );
        } else if (!!selectedDistributionListCount) {
            return (
                <div className="selected-count">
                    BWIC will be sent to <span className="text-medium">{selectedDistributionListCount} {distributionListText}</span> and to <span className="text-medium">{selectedParticipantCount} {participantText}</span> from <span className="text-medium">{selectedCompanyCount} {companyText}</span>
                </div>
            );
        } else {
            return (
                <div className="selected-count">
                    BWIC will be sent to <span className="text-medium">{selectedParticipantCount} {participantText}</span> from <span className="text-medium">{selectedCompanyCount} {companyText}</span>
                </div>
            );
        }
    };
    return (
        <>{!!selectedCompanyCount && renderLabel()}</>
    );
};
