import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { EventsSection } from './EventsSection';
import { newsActions } from '../../../actions/news.actions';
import { Preloader } from '../../common';
import { PrimaryMarketHighlights } from './charts/PrimaryMarketHighlights';
import { BwicsVolumeAndPrice } from './charts/BwicsVolumeAndPrice';
// import { MyPortfolio } from './charts/MyPortfolio';
import { TabItem, TabList } from '../../bidding/common/tab-list';
import { Pagination } from '../../dashboard/tables/Pagination';
import { News } from '../../../types/news/News';
import { NewsItem } from './NewsItem';
import { constants, routes } from '../../../constants';
import { NewsCategory, NewsTabToCategory, newsCategoryTitles } from '../../../types/news/enums/NewsCategory';
import Confirm from '../../alerts/Confirm';
import { NewsTabCategory } from '../../../types/news/enums/NewsTabCategory';
import NewsListContext from './NewsListContext';
import { NewsAccessType, newsAccessTypeByTab } from '../../../types/amr-pipeline/enums/NewsAccessType';
import { canEditNews } from '../../../utils/news.utils';
import { Link, useHistory } from 'react-router-dom';
import IconSVG from '../../../styles/svg-icons';
import { useQuery } from '../../../effects/useQuery';

const NewsTabs = [
    { text: 'All', value: NewsTabCategory.All },
    { text: newsCategoryTitles[NewsCategory.Primary], value: NewsTabCategory.Primary },
    { text: newsCategoryTitles[NewsCategory.Secondary], value: NewsTabCategory.Secondary },
    { text: newsCategoryTitles[NewsCategory.Research], value: NewsTabCategory.Research },
    // TODO: Will be implemented in the next iteration
    // { text: newsCategoryTitles[NewsCategory.MyPortfolio] value: NewsCategory.MyPortfolio },
];

interface NewsListRouteQuery {
    page?: number;
    search?: string;
    category?: NewsTabCategory;
}

const defaultPages = {
    [NewsTabCategory.All]: 1,
    [NewsTabCategory.Primary]: 1,
    [NewsTabCategory.Secondary]: 1,
    [NewsTabCategory.Research]: 1,
};

export const NewsList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { search, category, page } = useQuery<NewsListRouteQuery>();
    const withQuery = !!(search || category || page);

    const [activeTab, setActiveTab] = useState<NewsTabCategory>(category || NewsTabCategory.All);

    const [currentPage, setCurrentPage] = useState({
        ...defaultPages,
        ...(page && { [category || NewsTabCategory.All]: Number(page) }),
    });

    const newsRef = useRef<HTMLDivElement>(null);

    const [newsForDeleting, setNewsForDeleting] = useState<News>();

    const { events, isMarketingEventsLoading, isNewsLoading, news, totalNews } = useSelector((state: AppState) => state.news);

    const currentNews = news[activeTab].get(currentPage[activeTab]);

    const contextValue = {
        canEdit: canEditNews()
    };

    useEffect(() => {
        dispatch(newsActions.fetchMarketingEventsRequest());
        dispatch(newsActions.logUserActivity(NewsAccessType.news));

    }, [dispatch, currentNews, activeTab, currentPage]);

    useEffect(() => {
        const accessType = newsAccessTypeByTab[activeTab];

        dispatch(newsActions.logUserActivity(accessType));
    }, [activeTab, dispatch]);

    useEffect(() => {
        if (!newsRef.current) {
            return;
        }

        var bounding = newsRef.current.getBoundingClientRect();

        if (bounding.top < 0 || bounding.left < 0 || bounding.right > window.innerWidth || bounding.bottom > window.innerHeight) {
            newsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [currentNews]);

    useEffect(() => {
        if (!currentNews) {
            dispatch(newsActions.fetchNewsRequest(currentPage[activeTab], activeTab));
            dispatch(newsActions.fetchTotalNewsCountRequest());
        }
    }, [dispatch, currentNews, activeTab, currentPage]);

    const resetSearchQuery = () => {
        if(withQuery) {
            history.replace(routes.newsUrl());
        }
    }

    const renderNewsSection = () => {
        if (isNewsLoading) {
            return <Preloader inProgress />;
        }

        return (
            <Pagination
                currentPage={currentPage[activeTab]}
                itemsOnPage={constants.newsOnPage}
                total={totalNews[activeTab]}
                withGoToPageNavigation
                onPageChanged={(page: number) => {
                    resetSearchQuery();
                    setCurrentPage({
                        ...currentPage,
                        [activeTab]: page,
                    })
                }}
                list={currentNews}
            >
                <div className="news-items">
                    {currentNews?.map((item, index) => (
                        <NewsItem
                            ref={
                                search === item.referenceName ? newsRef : (index === 0 ? newsRef : null)
                            }
                            currentCategory={NewsTabToCategory[activeTab]}
                            onDeleting={(newsItem) => setNewsForDeleting(newsItem)}
                            key={item.referenceName}
                            newsItem={item}
                            currentPage={currentPage[activeTab]}
                        />
                    ))}
                </div>
            </Pagination>
        );
    };

    const renderChartsSection = (tab: NewsTabCategory) => {
        switch (tab) {
            case NewsTabCategory.Primary:
                return <PrimaryMarketHighlights />;
            case NewsTabCategory.Secondary:
                return <BwicsVolumeAndPrice />;
            // case NewsCategory.MyPortfolio:
            //     return <MyPortfolio />;
            default:
                return [
                    <PrimaryMarketHighlights key="primary-market-highlights" />,
                    <BwicsVolumeAndPrice key="bwics-volume-and-price" />,
                    // <MyPortfolio />,
                ];
        }
    };

    const handleDeleteNews = () => {
        dispatch(newsActions.deleteNews(newsForDeleting!.referenceName, currentPage[activeTab], activeTab));
        setNewsForDeleting(undefined);
    }

    const handleChangeTab = (value: NewsTabCategory) => {
        resetSearchQuery();
        setActiveTab(value);
    }

    return (
        <NewsListContext.Provider value={contextValue}>
            <div className="sub-header sub-header-head">
                <div className="sub-header-row flex-row type01">
                    <h1>K-Watch News</h1>
                    {contextValue.canEdit && (
                        <div className="flex-item-right">
                            <Link className="btn btn-ghost" to={routes.manageCreateNews}>
                                <IconSVG name="plus" width={16} height={16} />
                                <span>news</span>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
            <div className="tabs-content container-flex">
                <div className="tabs-data tabs-data-padding">
                    <EventsSection isLoading={isMarketingEventsLoading} events={events} />
                    <div className="tabs-container">
                        <TabList className="tabs-news">
                            {NewsTabs.map(({ text, value }) => (
                                <TabItem
                                    key={value}
                                    onClick={() => handleChangeTab(value)}
                                    active={activeTab === value}
                                    title={text}
                                />
                            ))}
                        </TabList>
                    </div>
                    <div className="news-wrap">
                        <div className="news-col-left">{renderNewsSection()}</div>
                        <div className="news-col-right dashboard">
                            <div className="dashboard-row">{renderChartsSection(activeTab)}</div>
                        </div>
                    </div>
                    {newsForDeleting && (
                        <Confirm
                            title="Remove News"
                            text={
                                <>
                                    Are you sure you want to remove <b>{newsForDeleting.title}</b> news?
                                </>
                            }
                            onConfirm={handleDeleteNews}
                            onCancel={() => setNewsForDeleting(undefined)}
                        />
                    )}
                </div>
            </div>
        </NewsListContext.Provider>
    );
};
