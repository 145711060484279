import { SectionContent } from '../../../amrPipeline/common/section/SectionContent'
import { BarScheduledBwicsWidget } from '../../../dashboard/barScheduledBwics/BarScheduledBwicsWidget';
import { DailyTradingStatsWidget } from '../../../dashboard/dailyTradingStats/DailyTradingStatsWidget';
import { HeatMapScheduledBwicsWidget } from '../../../dashboard/heatmapScheduledBwics/HeatmapScheduledBwicsWidget';
import { PriceLevelWidget } from '../../../dashboard/priceLevel/PriceLevelWidget';
import IconSVG from '../../../../styles/svg-icons';
import { routes } from '../../../../constants';
import { useHistory } from 'react-router';

export const BwicsVolumeAndPrice = () => {
    const history = useHistory();

    const handleGotoGraphs = () => {
        history.replace(`${routes.dashboard}#bwics-volume-and-price`);
    };

    return (
        <SectionContent
            title="BWICs Volume & Price"
            actionBtn={
                <button className="btn-link flex-item-right" onClick={handleGotoGraphs}>
                    <IconSVG name="go-to" width={16} height={16} />
                    All graphs
                </button>
            }
        >
            <BarScheduledBwicsWidget />
            <HeatMapScheduledBwicsWidget />
            <PriceLevelWidget />
            <DailyTradingStatsWidget collapsibleDescription/>
        </SectionContent>
    );
};
