import { Route, Switch } from 'react-router';
import { routes } from '../../constants';
import { NewsList } from './news-list/NewsList';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { newsActions } from '../../actions/news.actions';
import { NewsPage } from './news-page/NewsPage';
import { useScheduledBwics } from '../dashboard/hooks/useScheduledBwics';

export const News = () => {
    const dispatch = useDispatch();
    useScheduledBwics(false);

    useEffect(() => {
        return () => {
            dispatch(newsActions.reset());
        }
    }, [dispatch]);

    return (
        <div className="container container-news">
            <Switch>
                <Route exact path={routes.newsUrl()} component={NewsList} />
                <Route path={routes.news} component={NewsPage} />
            </Switch>
        </div>
    );
};
