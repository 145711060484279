import { capitalize } from 'lodash';
import { useHistory } from 'react-router';
import IconSVG from '../../../styles/svg-icons';
import { MarketingEvent } from '../../../types/marketing/MarketingEvent';
import { MarketingEventType, ReleaseItemType } from '../../../types/marketing/Release';
import { FallbackImage, ImageLoader } from '../../common/ImageLoader';
import classNames from 'classnames';
import { NewsAccessType } from '../../../types/amr-pipeline/enums/NewsAccessType';
import { useDispatch } from 'react-redux';
import { newsActions } from '../../../actions/news.actions';
import { OnHoverTooltip } from '../../common';
import { LocationState, LocationStateBuilder, PopupType } from '../../../types/state/ui/LocationState';
import { TWhatsNewPopupPayload } from '../../whats-new-popup/WhatsNewPopup';

interface EventWidgetProps {
    event: MarketingEvent;
    className?: string;
}

const EventTypeImagePath = {
    [MarketingEventType.Release]: 'release-image',
    [MarketingEventType.Webinar]: 'webinar-image',
    [MarketingEventType.Conference]: 'conference-image',
};

const ReleaseItemTypeToAccess: Partial<Record<ReleaseItemType, NewsAccessType>> = {
    [ReleaseItemType.NewFeature]: NewsAccessType.newFeatures,
    [ReleaseItemType.Improvement]: NewsAccessType.improvements,
};

const MarketingEventTypeToAccess: Partial<Record<MarketingEventType, NewsAccessType>> = {
    [MarketingEventType.Conference]: NewsAccessType.conference,
    [MarketingEventType.Webinar]: NewsAccessType.webinars,
};

const ImageFallback = (<div className="event-image-fallback">{FallbackImage}</div>);

export const EventWidget = ({ event, className }: EventWidgetProps) => {
    const dispatch = useDispatch();
    const history = useHistory<LocationState<undefined, TWhatsNewPopupPayload>>();

    const renderItemTitle = (item: MarketingEvent) => {
        if (item.type === MarketingEventType.Release) {
            const iconName = item.releaseItemType === ReleaseItemType.NewFeature
                ? 'favorite'
                : 'idea';

            return (
                <>
                    <IconSVG name={iconName} width={16} height={16} />
                    <span className="text-bold">{item.title}</span>
                </>
            );
        }

        return (
            <>
                <IconSVG name="calendar" width={16} height={16} />
                <span className="text-bold">{capitalize(item.type)}</span>
            </>
        );
    };

    const renderItemDescription = (item: MarketingEvent) => {
        if (item.type === MarketingEventType.Webinar) {
            if (item.speakerName && item.speakerTitle) {
                return (
                    <>
                        Speaker: <span className="text-bold">
                            <OnHoverTooltip overlay={`${item.speakerName}, ${item.speakerTitle}`}>
                                {item.speakerName}, {item.speakerTitle}
                            </OnHoverTooltip>
                        </span>
                    </>
                );
            }
        }

        return (
            <OnHoverTooltip overlay={item.description}>
                <span className="text-medium">
                    {item.description}
                </span>
            </OnHoverTooltip>
        );
    };

    const onEventClick = (marketingEvent: MarketingEvent) => (event: React.MouseEvent<HTMLElement>) => {
        let accessType: NewsAccessType | undefined = MarketingEventTypeToAccess[marketingEvent.type];

        if (marketingEvent.type === MarketingEventType.Release) {
            event.preventDefault();

            const { releaseItemType } = marketingEvent;

            accessType = ReleaseItemTypeToAccess[releaseItemType!];

            history.replace({
                ...history.location,
                state: new LocationStateBuilder(history.location.state)
                    .popup(PopupType.WhatsNewPopup, { releasesVisible: true, webinarVisible: false })
                    .result()
            });
        }

        accessType && dispatch(newsActions.logUserActivity(accessType));
    }

    const renderImage = () => {
        if (!event.imageReferenceName) {
            return ImageFallback;
        }

        return (
            <ImageLoader
                path={`${process.env.REACT_APP_MARKETING_ENDPOINT}/api/${EventTypeImagePath[event.type]}/v2/${event.imageReferenceName}`}
                fallback={ImageFallback}
            />
        );
    };

    const composeLink = () => event.type === MarketingEventType.Release ? '#' : event.link;

    return (
        <div className={classNames('event-item', className)}>
            <a href={composeLink()} onClick={onEventClick(event)} target="_blank" rel="noopener noreferrer" className="event-box">
                <div className={classNames('event-image', { 'event-image-center': event.type === MarketingEventType.Conference })}>
                    {renderImage()}
                </div>
                <div className="event-content">
                    <h3 className="event-title">{renderItemTitle(event)}</h3>
                    <div className="event-info">
                        <div className="event-name">
                            <span>{renderItemDescription(event)}</span>
                        </div>
                        <div className="event-link">
                            <span className="btn-link secondary">{event.linkName || 'Read more'}</span>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
};
