import React, { Component } from 'react'
import { connect } from 'react-redux';
import { WizardHeader } from '../';
import { documentsActions as actions, uploadDocumentsActions } from '../../../actions';
import { ManageDealDocuments } from '../../documents/ManageDealDocuments';

class Documents extends Component {
    componentDidMount = () => {
        const { dispatch, bwic } = this.props;

        dispatch(actions.init(bwic));
        this.interval = setInterval(() => dispatch(uploadDocumentsActions.refresh()), 20000);
    }

    componentWillUnmount = () => clearInterval(this.interval);

    render = () => {
        return (
            <>
                <div className="sub-header">
                    <WizardHeader isStepNavigationDisabled={this.props.isUploading}/>
                </div>
                <ManageDealDocuments />
            </>
        );
    }
}

const mapStateToProps = ({ newBWIC, uploadDocuments }) => ({
    bwic: newBWIC.bwic,
    isUploading: uploadDocuments.isUploading
});

const connectedDocuments = connect(mapStateToProps)(Documents);
export { connectedDocuments as Documents };
