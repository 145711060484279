import { MarketingEvent } from '../../../types/marketing/MarketingEvent';
import { Preloader } from '../../common';
import { FallbackImage } from '../../common/ImageLoader';
import { EventWidget } from './EventWidget';

interface EventsSectionProps {
    events: MarketingEvent[];
    isLoading: boolean;
}

export const EventsSection = ({ events, isLoading }: EventsSectionProps) => {
    if (isLoading) {
        return (
            <div className="event-row-loader">
                <Preloader inProgress />
            </div>
        );
    }

    const renderFallback = () => (
        <div className="event-item">
            <div className="event-image event-image-wrap">
                <div className="event-image-fallback">
                    {FallbackImage}
                    <p>Webinars, Conferences, New Features, and Improvements are not available for display at the moment.</p>
                </div>
            </div>
        </div>
    );

    return (
        <div className="event-row-wrap">
            <div className="event-row">
                {events.length
                    ? events.map((event, index) => (
                        <EventWidget key={`${event.type}-${index}`} event={event} />
                    ))
                    : renderFallback()
                }
            </div>
        </div>
    );
};
